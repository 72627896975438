<template>
  <loading :active="isLoading" :is-full-page="true" :loader="'bars'" />
  <user-index class="flex items-center justify-center h-full">
    <div
      class="flex items-center justify-center w-full h-screen overflow-y-auto"
    >
      <div
        class="flex flex-col h-full  place-content-between x-sm:w-full sm:w-2/3 lg:w-1/3 x-sm:px-4 sm:px-0 lg:px-0 u-signup"
      >
        <div class="flex justify-center p-4">
          <div class="flex justify-start w-full">
            <img
              class="object-scale-down h-24"
              src="../../assets/img/LoginLogo.png"
            />
          </div>
        </div>
        <div class="p-1 mx-4">
          <form method="post" autocomplete="off" novalidate>
            <div class="flex flex-col justify-center w-full">
              <div class="flex flex-col items-start justify-start mb-6">
                <p class="text-4xl font-bold text-qgo-primary-bg">
                  TICKET SCANNED
                </p>
                <p class="text-xl text-white">
                  Enter your details to confirm order:
                </p>
              </div>
              <div class="relative flex flex-wrap items-stretch w-full mb-3">
                <input
                  name="userFullName"
                  type="text"
                  @blur="onBlur('name')"
                  v-model="input.name"
                  @input="nameVerification"
                  :disabled="showOTPInput"
                  class="
                    mt-0
                    block
                    w-full
                    px-0.5
                    border-0 border-b-2 border-gray-600
                    text-white
                    focus:ring-0
                    bg-qgo-secondary-bg
                  "
                  :class="
                    !validStatus.name
                      ? 'focus:border-red-500'
                      : 'focus:border-qgo-primary-bg'
                  "
                  placeholder="Full Name"
                  required
                />
                <span
                  class="absolute z-10 items-center justify-center w-8 h-full py-3 pr-3 text-xs font-normal leading-snug text-center text-gray-400 bg-transparent rounded  right-7"
                  :class="!validStatus.name ? 'text-red-500' : 'text-gray-400'"
                >
                  REQUIRED
                </span>
                <p v-if="!validStatus.name" class="text-left text-red-500">
                  {{ errors.name }}
                </p>
              </div>

              <!-- <div class="relative flex flex-wrap items-stretch w-full mb-3">
                <input
                  name="userEmail"
                  @blur="onBlur('email')"
                  v-model="input.email"
                  @input="emailVerification"
                  type="email"
                  :disabled="showOTPInput"
                  class="
                    mt-0
                    block
                    w-full
                    px-0.5
                    border-0 border-b-2
                    text-white
                    border-gray-600
                    focus:ring-0
                    bg-qgo-secondary-bg
                  "
                  :class="
                    !validStatus.email
                      ? 'focus:border-red-500'
                      : 'focus:border-qgo-primary-bg'
                  "
                  placeholder="Email Address"
                  required
                />
                <span
                  class="absolute z-10 items-center justify-center w-8 h-full py-3 pr-3 text-xs font-normal leading-snug text-center bg-transparent rounded  right-7"
                  :class="!validStatus.email ? 'text-red-500' : 'text-gray-400'"
                >
                  REQUIRED
                </span>
                <p v-if="!validStatus.email" class="text-left text-red-500">
                  {{ errors.email }}
                </p>
              </div> -->

              <div class="flex flex-row">
                <!-- <span class="flex self-center mb-3 px-0.5 text-white">
                  +61
                </span> -->
                <div class="relative flex flex-wrap items-stretch w-full mb-3">
                  <vue-tel-input
                    class="w-full"
                    @blur="onBlur('phn')"
                    @input="onPhnInput"
                    v-model="input.phone_number"
                    mode="international"
                  ></vue-tel-input>
                  <!-- <input
                    name="userNumber"
                    v-model="input.phone_number"
                    @blur="onBlur('phn')"
                    :disabled="showOTPInput"
                    @input="phoneNumberVerification"
                    type="tel"
                    pattern="^[0-9]{10}$"
                    minlength="9"
                    maxlength="10"
                    class="
                      mt-0
                      block
                      w-full
                      px-0.5
                      border-0 border-b-2
                      text-white
                      border-gray-600
                      focus:ring-0
                      bg-qgo-secondary-bg
                    "
                    :class="
                      !validStatus.phone_number
                        ? 'focus:border-red-500'
                        : 'focus:border-qgo-primary-bg'
                    "
                    placeholder="Phone Number(491123456)"
                    required
                    v-on:keyup.enter="joinQueue"
                  /> -->
                  <span
                    class="absolute z-10 items-center justify-center w-8 h-full py-3 pr-3 text-xs font-normal leading-snug text-center bg-transparent rounded  right-7"
                    :class="
                      !validStatus.phone_number
                        ? 'text-red-500'
                        : 'text-gray-400'
                    "
                  >
                    REQUIRED
                  </span>
                  <p
                    v-if="!validStatus.phone_number"
                    class="text-left text-red-500"
                  >
                    {{ errors.phone_number }}
                  </p>
                </div>
              </div>
              <div
                v-if="showOTPInput"
                class="relative flex flex-wrap items-stretch w-full mb-3"
              >
                <input
                  name="OTP"
                  type="text"
                  @blur="onBlur('otp')"
                  v-model="input.otp"
                  @input="validateOTP"
                  class="
                    mt-0
                    block
                    w-full
                    px-0.5
                    border-0 border-b-2 border-gray-600
                    text-white
                    focus:ring-0
                    bg-qgo-secondary-bg
                  "
                  :class="
                    !validStatus.otp
                      ? 'focus:border-red-500'
                      : 'focus:border-qgo-primary-bg'
                  "
                  placeholder="Enter OTP"
                  required
                />
                <span
                  class="absolute z-10 items-center justify-center w-8 h-full py-3 pr-3 text-xs font-normal leading-snug text-center text-gray-400 bg-transparent rounded  right-7"
                  :class="!validStatus.otp ? 'text-red-500' : 'text-gray-400'"
                >
                  REQUIRED
                </span>
                <p v-if="!validStatus.top" class="text-left text-red-500">
                  {{ errors.otp }}
                </p>
              </div>
            </div>
            <div v-if="!showOTPInput" class="flex flex-col w-full">
              <button
                @click="joinQueue"
                type="button"
                class="p-3 my-8 font-extrabold text-white rounded-md  bg-qgo-primary-bg focus:outline-none"
              >
                JOIN THE QUEUE
              </button>
            </div>
            <div v-else class="flex flex-col w-full">
              <button
                @click="submitOTP"
                type="button"
                class="p-3 my-8 font-extrabold text-white rounded-md  bg-qgo-primary-bg focus:outline-none"
              >
                VERIFY OTP
              </button>
            </div>
          </form>
        </div>
        <div class="flex pt-1">
          <div class="flex pt-1 mx-4 mt-4 x-sm:w-2/5 sm:w-1/2 md:w-1/2 h-52">
            <Card class="w-36" :queue="scannedQrData"></Card>
          </div>
          <div
            class="flex flex-col items-start justify-start py-4 text-white  x-sm:px-2 sm:px-4 md:px-4 x-sm:3/5 sm:w-2/3 md:w-2/3"
          >
            <p class="flex flex-wrap text-md">No Need to create</p>
            <p class="flex flex-wrap text-md">an account right now.</p>
            <br />
            <p class="flex flex-wrap text-sm">We will email you with</p>
            <p class="flex flex-wrap text-sm">instructions for next time.</p>
          </div>
        </div>
      </div>
    </div>
  </user-index>
</template>

<script>
import UserIndex from "@/views/User/Index";
import Card from "@/views/User/Queue/QueueCards.vue";
import Loading from "vue-loading-overlay";

export default {
  data() {
    return {
      phnData: {},
      scannedQrData: {
        order_no: null,
        order_id:null,
        vendor_id: null,
        vendor_detail: { logo: null },
      },
      userID: "",
      isLoading: false,
      showOTPInput: false,
      userFullName: "",
      userEmail: "",
      userNumber: null,
      validators: {
        userFullName: false,
        userEmail: false,
        userNumber: false,
        otp: false,
      },
      blurStatus: {
        email: "",
        phone_number: "",
        name: "",
      },
      input: {
        email: "",
        phone_number: "",
        name: "",
        otp: "",
        country_code: "",
      },
      errors: {
        email: "",
        phone_number: "",
        name: "",
        otp: "",
      },
      validStatus: {
        email: true,
        phone_number: true,
        name: true,
        otp: true,
      },
    };
  },
  watch: {
    userFullName(value) {
      this.userFullName = value;
      this.validateUserFullName(value);
    },
    userEmail(value) {
      this.userEmail = value;
      this.validateUserEmail(value);
    },
    userNumber(value) {
      this.userNumber = value;
      this.validateUserNumber(value);
    },
  },
  
  components: {
    UserIndex,
    Card,
    Loading,
  },
  methods: {
    onPhnInput(value, data) {
      if (data && data.valid) {
        this.phnData = data;
        if (this.blurStatus.phone_number) {
          this.errors.phone_number = "";
          this.validStatus.phone_number = true;
          this.input.phone_number = data.nationalNumber;
          this.input.country_code = data.country
            ? data.country.dialCode
              ? data.country.dialCode
              : ""
            : "";
        }
      } else if (data && !data.valid) {
        this.phnData = data;
        if (this.blurStatus.phone_number) {
          this.errors.phone_number = "Invalid phone number";
          this.validStatus.phone_number = false;
          this.input.phone_number = data.nationalNumber;
          this.input.country_code = data.country
            ? data.country.dialCode
              ? data.country.dialCode
              : ""
            : "";
        }
      }
      if (this.blurStatus.phone_number && !this.input.phone_number) {
        this.errors.phone_number = "Phone number is required";
        this.validStatus.phone_number = false;
      }
    },
    submitOTP() {
      this.onBlur("OTP");
      if (this.validStatus.otp) {
        this.isLoading = true;
        this.$http
          .post("auth/verify-otp", {
            user_id: this.userID,
            otp: this.input.otp,
          })
          .then((response) => {
            if (response && response.data.status) {
              this.$swal.fire({
                icon: "success",
                title: response.data.message,
                timer: 3000,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
              });
              this.$router.push({ name: "JoiningLoad" });
              // if (response.data.userData.role == "vendor") {
              //   this.$router.push({ name: "VendorSignin" });
              // } else {
              //   this.$router.push({ name: "UserSignIn" });
              // }
            }
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error.response.data);
            this.$swal.fire({
              icon: "error",
              title: error.response.data.message,
              timer: 3000,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
            });
          });
      }
    },
    joinQueue() {
      // this.onBlur("email");
      this.onBlur("phn");
      this.onBlur("name");
      // if (!this.scannedQrData.order_no || !this.scannedQrData.vendor_id) {
      //   this.$swal.fire({
      //     icon: "error",
      //     title: "Something went wrong. Error while scanning QR",
      //     timer: 3000,
      //     toast: true,
      //     position: "top-end",
      //     showConfirmButton: false
      //   });
      //   return;
      // }
      if (
        // this.validStatus.email &&
        this.validStatus.phone_number &&
        this.validStatus.name
      ) {
        this.isLoading = true;
        let reqData = {
          name: this.input.name,
          email: this.input.email,
          phone_number: this.input.phone_number,
          country_code: this.input.country_code,
        };
        this.$http
          .post("/api/v1/auth/phone/register", reqData)
          .then((response) => {
            this.isLoading = false;
            this.showOTPInput = true;
            this.userID = response.data.user.id;
            let data = {
              id: response.data.user.id,
              email: response.data.user.email,
              isLoggedIn: true,
              access_token: response.data.access_token,
              role: 1,
              name: response.data.user.name,
            };
            this.$store.dispatch("auth/setLoginSuccess", data);
            this.$swal.fire({
              icon: "success",
              title: response.data.message,
              timer: 3000,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
            });
            this.$router.push({ name: "JoiningLoad" });
          })
          .catch((err) => {
            this.isLoading = false;
            this.$swal.fire({
              icon: "error",
              title: err.response.data.message,
              timer: 5000,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
            });
          });
      }
      // let body = {
      //   name: this.userFullName,
      //   email: this.userEmail,
      //   role: "customer",
      //   phone_number: `+61${this.userNumber}`,
      //   vendor_id: this.$route.query.vendor_id,
      //   id: this.queue.id,
      // };
      // this.$store.commit("queues/setOrderDetail", body);
      // this.$router.push({ name: "JoiningLoad" });
    },
    onBlur(field) {
      switch (field) {
        case "email":
          this.blurStatus.email = true;
          this.emailVerification();
          break;
        case "phn":
          this.blurStatus.phone_number = true;
          this.onPhnInput("", this.phnData);
          // this.phoneNumberVerification();
          break;
        case "name":
          this.blurStatus.name = true;
          this.nameVerification();
          break;
        case "otp":
          this.blurStatus.otp = true;
          this.validateOTP();
          break;
        default:
          break;
      }
    },
    validateOTP() {
      if (this.blurStatus.otp) {
        if (this.input.otp) {
          let n = this.input.otp.toString();
          let regex = /^\d+$/;
          if (regex.test(n)) {
            if (n.length == 6) {
              this.errors.otp = "";
              this.validStatus.otp = true;
            } else {
              this.errors.otp = "OTP should be of 6 numbers";
              this.validStatus.otp = false;
            }
          } else {
            this.errors.otp = "Invalid OTP(only digits are allowed)";
            this.validStatus.otp = false;
          }
        } else {
          this.errors.otp = "OTP is required";
          this.validStatus.otp = false;
        }
      }
    },
    emailVerification() {
      if (this.blurStatus.email) {
        if (this.input.email) {
          const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (re.test(this.input.email)) {
            this.errors.email = "";
            this.validStatus.email = true;
          } else {
            this.errors.email = "Please enter a valid email";
            this.validStatus.email = false;
          }
        } else {
          this.errors.email = "Email is required";
          this.validStatus.email = false;
        }
      } else {
        this.errors.email = "";
        this.validStatus.email = true;
      }
    },
    phoneNumberVerification() {
      if (this.blurStatus.phone_number) {
        if (this.input.phone_number) {
          const rgex = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
          if (
            rgex.test(this.input.phone_number) &&
            this.input.phone_number.length > 9 &&
            this.input.phone_number.length < 15
          ) {
            this.errors.phone_number = "";
            this.validStatus.phone_number = true;
          } else {
            this.errors.phone_number = "Invalid phone number";
            this.validStatus.phone_number = false;
          }
        } else {
          this.errors.phone_number = "Phone number is required";
          this.validStatus.phone_number = false;
        }
      } else {
        this.errors.phone_number = "";
        this.validStatus.phone_number = true;
      }
    },
    nameVerification() {
      if (this.blurStatus.name) {
        if (this.input.name) {
          if (this.input.name.length < 2 || this.input.name.length > 100) {
            this.errors.name =
              "Name should be greater than 2 and less than 100 characters";
            this.validStatus.name = false;
          } else {
            this.errors.name = "";
            this.validStatus.name = true;
          }
        } else {
          this.errors.name = "Name is required";
          this.validStatus.name = false;
        }
      } else {
        this.errors.name = "";
        this.validStatus.name = true;
      }
    },

    // onPageLoad() {
    //   // Debug route query parameters
    //   console.log("this:", this);

    //   console.log("Route Query:", {
    //     vender_id: this.$route.query.vender_id,
    //     order_id: this.$route.query.order_id,
    //     vendor_logo: this.$route.query.vendor_logo,
    //   });
    //   let payload = {
    //     vendor_id: this.$route.query.vender_id,
    //     order_id: this.$route.query.order_id,
    //     vendor_logo: this.$route.query.vendor_logo,
    //   };
    //   console.log("Initial Payload:", payload);

    //   // Debug payload values
    //   console.log("Payload Check:", {
    //     hasVendorId: Boolean(payload.vendor_id),
    //     hasOrderId: Boolean(payload.order_id),
    //     payloadComplete: Boolean(payload.vendor_id && payload.order_id),
    //   });
    //   if (payload.vendor_id && payload.order_id) {
    //     let data = {
    //       vendor_id: payload.vendor_id,
    //       order_id: payload.order_id,
    //       vendor_logo: payload.vendor_logo,
    //     };
    //     console.log("Data to be stored:", data);

    //     this.scannedQrData = {
    //       vendor_id: data.vendor_id,
    //       order_no: data.order_id,
    //       vendor_detail: {
    //         logo: data.vendor_logo,
    //       },
    //     };
    //     // this.scannedQrData = {
    //     //   order_no: "test123",
    //     //   vendor_id: "test456",
    //     //   vendor_detail: {
    //     //     logo: ""
    //     //   }
    //     // };
    //     console.log("Scanned QR Data:", this.scannedQrData);

    //     localStorage.setItem("scannedQRDetails", JSON.stringify(data));
    //     if (
    //       localStorage.getItem("userData") &&
    //       localStorage.getItem("access")
    //     ) {
    //       let userData = JSON.parse(localStorage.getItem("userData"));
    //       let access = JSON.parse(localStorage.getItem("access"));

    //       if (userData.role == 1) {
    //         let data = {
    //           id: userData.id,
    //           email: userData.email,
    //           isLoggedIn: true,
    //           access_token: access.access_token,
    //           role: 1,
    //           name: userData.name,
    //         };
    //         this.$store.dispatch("auth/setLoginSuccess", data);
    //         this.$router.push({ name: "JoiningLoad" });
    //       }
    //     }
    //     //proceed
    //   } else {
    //     this.$swal.fire({
    //       icon: "error",
    //       title: "Something went wrong. Error while scanning QR",
    //       timer: 3000,
    //       toast: true,
    //       position: "top-end",
    //       showConfirmButton: false,
    //     });
    //     //show error that somewthing went wrong
    //   }

    //   // this.$store.dispatch("queues/onPageLoad", payload);
    // },
    onPageLoad() {
  console.log("this:", this);

  // Fetch query parameters from the route
  const vendor_id = this.$route.query.vender_id;
  const order_id = this.$route.query.order_id;
  const vendor_logo = this.$route.query.vendor_logo;

  console.log("Route Query:", { vendor_id, order_id, vendor_logo });

  // Validate query parameters
  if (!vendor_id || !order_id) {
    console.error("Missing Required Query Parameters: vendor_id or order_id");
    this.$swal.fire({
      icon: "error",
      title: "Invalid QR Code",
      text: "Vendor ID or Order ID is missing in the QR Code.",
      timer: 3000,
      toast: true,
      position: "top-end",
      showConfirmButton: false,
    });
    return;
  }

  // Prepare payload
  let payload = {
    vendor_id,
    order_id,
    vendor_logo,
  };

  console.log("Initial Payload:", payload);

  
  this.$http
    .get(`/api/v1/qr/get-orderno-vendor-logo?vendor_id=${payload.vendor_id}&order_id=${payload.order_id}`)
    .then((response) => {
      console.log("API Response:", response.data);
      

      const { status, logo, orderno, message } = response.data;

      // Check if API call was successful
      if (status && logo && orderno) {
        console.log("Fetched Data:", { logo, orderno });

        // Store data
        const data = {
          vendor_id,
          order_id: payload.order_id,
          order_no: orderno,
          vendor_logo: logo,
        };

        console.log("Final Data to be stored:", data);

        this.scannedQrData = {
          vendor_id: data.vendor_id,
          order_no: data.order_no,
          order_id: data.order_id,
          vendor_detail: {
            logo: data.vendor_logo,
          },
        };

        // Save data in localStorage
        localStorage.setItem("scannedQRDetails", JSON.stringify(data));

        // Proceed if user is logged in
        if (
          localStorage.getItem("userData") &&
          localStorage.getItem("access")
        ) {
          let userData = JSON.parse(localStorage.getItem("userData"));
          let access = JSON.parse(localStorage.getItem("access"));

          if (userData.role == 1) {
            let loginData = {
              id: userData.id,
              email: userData.email,
              isLoggedIn: true,
              access_token: access.access_token,
              role: 1,
              name: userData.name,
            };

            this.$store.dispatch("auth/setLoginSuccess", loginData);
            this.$router.push({ name: "JoiningLoad" });
          }
        }
      } else {
        // Handle invalid or incomplete API response
        console.error("Invalid API Response:", message);
        throw new Error(message || "Data fetching failed.");
      }
    })
    .catch((error) => {
      // Differentiate errors for better debugging
      console.error("Error fetching vendor details:", error);

      let errorMessage = "Something went wrong while fetching vendor details.";

      if (error.response) {
        // Server returned an error response
        console.error("API Error Response:", error.response.data);
        errorMessage = error.response.data.message || "Server error occurred.";
      } else if (error.request) {
        // Request was made but no response was received
        console.error("No Response Received:", error.request);
        errorMessage = "No response from server. Please try again later.";
      } else {
        // General error
        console.error("Unexpected Error:", error.message);
      }

      this.$swal.fire({
        icon: "error",
        title: "Failed to Fetch Data",
        text: errorMessage,
        timer: 3000,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
      });
    });
},

   
    validateUserFullName(value) {
      this.validators.userFullName = this.validateEmpty(value);
    },
    validateUserEmail(value) {
      this.validators.userEmail = this.validateEmpty(value);
      // /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)
    },
    validateUserNumber(value) {
      this.validators.userNumber = this.validateEmpty(value);
    },
    validateEmpty(value) {
      return value ? false : true;
    },
  },
  computed: {
    queue() {
      return this.$store.getters["queues/singleQueueData"];
    },
  },
  mounted() {
    this.onPageLoad();
  },
};
</script>
<style lang="scss">
.u-signup {
  :focus-visible {
    outline: none;
  }
  .vti__input {
    background-color: transparent;
    border: none;
  }
  .vue-tel-input {
    border: none;
    border-bottom: 2px solid rgba(75, 85, 99, 1);
    color: #fff;
  }
  .vue-tel-input:focus-within {
    box-shadow: none;
    border-color: rgba(75, 85, 99, 1);
  }
  [type="tel"]:focus {
    outline: none;
    outline-offset: 0px;
    --tw-ring-inset: 0;
    --tw-ring-offset-width: 0px;
    --tw-ring-color: transparent;
    --tw-ring-offset-shadow: none;
    --tw-ring-shadow: none;
    box-shadow: none;
    border-color: none;
  }
  .vti__dropdown-item {
    color: #000;
  }
  .vti__dropdown:hover {
    background-color: transparent;
  }
}
</style>
