<template>
  <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />

  <div class="w-full min-h-screen px-6 bg-teal-900">
    <!-- Header -->
    <AdminHeader />

    <!-- Main Content -->
    <div class="max-w-4xl pb-8 mx-auto">
      <!-- Title -->
      <h1 class="mb-10 text-2xl text-left text-white">
        Vendors
        <span class="-ml-1 text-4xl" style="color: rgb(0, 163, 136);">.</span>
      </h1>

      <!-- Two Column Layout -->
      <div
        v-if="!isLoading && vendorData"
        class="flex flex-col gap-8 md:flex-row"
      >
        <!-- Left Column -->
        <div class="w-full space-y-6 md:w-1/2">
          <div>
            <label class="block mb-2 text-left text-white text-md"
              >Business Name</label
            >
            <input
              type="text"
              v-model="businessName"
              placeholder="Jimmy's Cafe"
              class="w-full p-4 bg-white rounded-md focus:outline-none"
            />
          </div>

          <div>
            <label class="block mb-2 text-left text-white text-md"
              >Address</label
            >
            <input
              type="text"
              v-model="address"
              placeholder="14 Smith Street, Suburb, STATE 0000"
              class="w-full p-4 bg-white rounded-md focus:outline-none"
            />
          </div>
          <div class="relative flex-1">
            <label class="block mb-2 text-left text-white text-md">State</label>
            <select
              class="w-full p-3 bg-white rounded-md appearance-none focus:outline-none"
              v-model="selectedStateId"
            >
              <option value="" disabled>Select State</option>
              <option
                v-for="state in states"
                :key="state.id"
                :value="state.id"
                @change="updateSelectedStateName"
              >
                {{ state.name }}
              </option>
            </select>
            <!-- <div
              class="absolute flex transform -translate-y-1/2 pointer-events-none right-3 top-1/2"
            >
              <span
                class="items-center justify-center material-icons arrow-down-icon"
              >
                arrow_drop_down
              </span>
            </div> -->
          </div>

          <div>
            <label class="block mb-2 text-left text-white text-md"
              >Country</label
            >
            <input
              type="text"
              v-model="country"
              placeholder="14 Smith Street, Suburb, STATE 0000"
              class="w-full p-4 bg-white rounded-md focus:outline-none"
            />
          </div>
          <div>
            <label class="block mb-2 text-left text-white text-md">Phone</label>
            <input
              type="tel"
              v-model="phone"
              placeholder="0000 0000"
              class="w-full p-4 bg-white rounded-md focus:outline-none"
            />
          </div>

          <div>
            <label class="block mb-2 text-left text-white text-md">Email</label>
            <input
              type="email"
              v-model="email"
              placeholder="jimmy@cafe.com"
              class="w-full p-4 bg-white rounded-md focus:outline-none"
            />
          </div>

          <div class="pt-4">
            <div class="flex items-center gap-2 mb-4">
              <span class="text-white text-md">Scans</span>
              <span class="text-2xl text-white">#252</span>
            </div>
            <div class="space-y-3 md:w-40">
              <button
                @click="goToInvoices"
                class="w-full py-4 text-white rounded"
                style="background-color: #00BCD4"
              >
                Invoices
              </button>
              <button
                @click="showCreditModal = true"
                class="w-full py-4 text-white rounded"
                style="background-color: #00A388"
              >
                Apply Credit
              </button>
              <!-- Edit Button -->

              <button
                @click="updateVendor"
                class="w-full py-4 text-white rounded"
                style="background-color: #FFA726"
              >
                Save
              </button>

              <button
                @click="deleteVendor"
                class="w-full py-4 text-white rounded"
                style="background-color: #E91E63"
              >
                Delete
              </button>
            </div>
          </div>
        </div>

        <!-- Right Column -->
        <div class="w-full space-y-4 md:w-1/2">
          <!-- Business Logo Box -->
          <div
            class="flex items-center justify-center w-3/5 h-32 ml-auto text-gray-400 bg-white rounded-md"
          >
            <img
              :src="vendorImage"
              alt="Vendor Logo"
              class="object-contain w-full h-full"
            />
          </div>

          <!-- Account Status and Credit -->
          <div class="space-y-2">
            <div class="flex items-baseline justify-end gap-2">
              <span class="text-lg text-white">Account Status</span>
              <span
                class="text-[#00A388] text-4xl leading-none"
                style="color:#00A388"
                >Active</span
              >
            </div>
            <div class="flex items-baseline justify-end gap-2">
              <span class="text-lg text-white">Credit</span>
              <span class="text-4xl text-white">${{ amount }}</span>
            </div>
          </div>

          <!-- Subject Field -->
          <div>
            <label class="block mb-2 text-left text-white text-md"
              >Subject</label
            >
            <input
              type="text"
              v-model="subject"
              placeholder="Enter subject"
              class="w-full p-4 bg-white rounded-md focus:outline-none"
            />
          </div>

          <!-- Notes Section -->
          <div>
            <label class="block mb-2 text-left text-white text-md">Notes</label>
            <textarea
              v-model="noteContent"
              class="w-full h-24 p-4 mb-4 bg-white rounded-md resize-none focus:outline-none"
              placeholder="Type Here"
            ></textarea>
          </div>

          <!-- Submit Button -->
          <div class="flex justify-end">
            <button
              @click="addNote"
              class="w-24 py-2 text-white rounded"
              style="background-color: #00A388"
            >
              Submit
            </button>
          </div>

          <!-- Messages Section -->
          <div class="mt-6 space-y-4">
            <!-- Loop through notes in reverse order -->
            <div
              v-for="note in [...vendorData.notes].reverse()"
              :key="note.id"
              :class="{
                'opacity-20 transition-opacity duration-200':
                  editingNote && editingNote.id !== note.id,
              }"
            >
              <div class="flex justify-between mb-1 text-xs text-gray-400">
                <span>{{ formatDate(note.created_at) }}</span>
                <div class="flex gap-2">
                  <button
                    @click="startEditingNote(note)"
                    class="flex items-center justify-center w-6 h-6 rounded-full"
                    style="background-color: rgba(255, 255, 255, 0.1)"
                  >
                    <span
                      class="text-white material-icons"
                      style="font-size: 12px;"
                      >edit_square</span
                    >
                  </button>
                  <button
                    @click="deleteNote(note.id)"
                    class="flex items-center justify-center w-6 h-6 rounded-full"
                    style="background-color: rgba(255, 255, 255, 0.1)"
                    :disabled="editingNote"
                    :class="{ 'opacity-50 cursor-not-allowed': editingNote }"
                  >
                    <span
                      class="text-white material-icons"
                      style="font-size: 12px;"
                      >close</span
                    >
                  </button>
                </div>
              </div>

              <!-- Show edit form if editing this note, otherwise show note content -->
              <div v-if="editingNote && editingNote.id === note.id">
                <input
                  type="text"
                  v-model="editingNote.subject"
                  class="w-full p-2 mb-2 bg-white rounded-md focus:outline-none"
                />
                <textarea
                  v-model="editingNote.content"
                  class="w-full h-24 p-2 mb-2 bg-white rounded-md resize-none focus:outline-none"
                ></textarea>
                <div class="flex justify-end gap-2">
                  <button
                    @click="cancelEditingNote"
                    class="px-4 py-1 text-white rounded"
                    style="background-color: #666"
                  >
                    Cancel
                  </button>
                  <button
                    @click="saveEditedNote"
                    class="px-4 py-1 text-white rounded"
                    style="background-color: #00A388"
                  >
                    Save
                  </button>
                </div>
              </div>

              <!-- Regular note display -->
              <div v-else>
                <div class="mb-1 text-left text-white text-md">
                  {{ note.subject }}
                </div>
                <div class="text-sm text-left text-gray-400">
                  {{ note.content }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Back Button -->
      <button
        @click="goBack"
        class="flex items-center gap-5 px-6 py-4 space-x-2 text-white rounded-md mt-14"
        style="background-color: #204952"
      >
        <span class="text-xl material-icons arrow-left-icon">arrow_left</span>
        <span>Back</span>
      </button>
    </div>
  </div>

  <div
    v-if="showCreditModal"
    class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
  >
    <div class="w-full max-w-4xl p-6 mx-6 bg-teal-900 rounded-lg md:mx-0">
      <!-- Modal Header -->
      <div class="flex items-center justify-between mb-6">
        <h2 class="text-xl text-white">
          Add Credit<span style="color: #00A388;">.</span>
        </h2>
        <button @click="showCreditModal = false" class="text-xl text-white">
          x
        </button>
      </div>

      <!-- Current Balance -->
      <div class="flex justify-end">
        <div>
          <span class="text-white">Current </span>
          <span class="text-3xl" style="color: #00A388;">Balance </span>
          <span class="ml-2 text-2xl text-white">${{ amount }}</span>
        </div>
      </div>

      <!-- Credit Form -->
      <div class="space-y-4">
        <div>
          <label class="block mb-2 text-left text-white"
            >Reason for Credit</label
          >
          <textarea
            v-model="creditReason"
            class="w-full p-4 bg-white rounded-md resize-none focus:outline-none"
            rows="4"
          ></textarea>
        </div>

        <div>
          <label class="block mb-2 text-left text-white">Apply Credit</label>
          <div class="flex gap-2 mb-10">
            <!-- Container for $ input and dropdown (80%) -->
            <div class="flex w-4/5 gap-2">
              <!-- Amount Input with $ (40% of the 80%) -->
              <div class="relative w-1/2">
                <div
                  class="absolute top-0 bottom-0 flex items-center text-gray-500 left-4"
                >
                  $
                </div>
                <input
                  type="text"
                  v-model="creditAmount"
                  class="w-full p-4 pl-8 bg-white rounded-md focus:outline-none"
                  placeholder="0"
                />
              </div>

              <!-- Dropdown (40% of the 80%) -->
              <div class="w-1/2">
                <select
                  v-model="creditType"
                  class="relative w-full p-4 bg-white rounded-md appearance-none focus:outline-none"
                  style="color: #06262d;"
                >
                  <option value="apply_credit">Apply Credit</option>
                  <option value="deducted">Deduct Credit</option>
                </select>
              </div>
            </div>

            <!-- Submit Button (20%) -->
            <button
              @click="applyCredit"
              class="w-1/5 py-4 text-white rounded-md"
              style="background-color: #00A388"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import AdminHeader from "@/components/AdminHeader.vue";
import Loading from "vue-loading-overlay";

export default {
  name: "Vendor",
  components: {
    AdminHeader,
    Loading,
  },
  data() {
    return {
      logo_transition: false,
      vendorData: null,
      isLoading: false,
      isEditing: false,
      fullPage: true,
      loader: "bars",
      businessName: "",
      email: "",
      phone: "",
      address: "",
      state: "",
      country: "",
      countryCode: "",
      vendorImage: "",
      amount: 0,
      subject: "",
      noteContent: "",
      editingNote: null,
      showCreditModal: false,
      creditReason: "",
      creditAmount: "",
      creditType: "apply_credit",
    };
  },
  mounted() {
    this.logo_transition = true;
    this.fetchVendorData();
  },
  methods: {
    goBack() {
      this.$router.push({ name: "AdminVendors" });
    },
    updateSelectedStateName() {
      const selectedState = this.states.find(
        (state) => state.id === this.selectedStateId
      );
      if (selectedState) {
        this.state = selectedState.name; // Set the full state name
      }
    },
    async fetchVendorData() {
      const vendorId = this.$route.query.id;
      if (!vendorId) return;

      this.isLoading = true;
      try {
        const response = await axios.get(`/vendor/${vendorId}`, {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("access")).access_token
            }`,
          },
        });
        console.log("Vendor Response:", response.data);

        if (response.data.status) {
          this.vendorData = {
            ...response.data.vendor,
            notes: response.data.notes || [],
          };
          this.states = response.data.states;
          this.amount = response.data.wallet?.amount || "0.00";
          this.populateFormFields();
        } else {
          // Handle case where API returns status false
          this.vendorData = null;
          throw new Error(response.data.message || "Vendor not found");
        }
      } catch (error) {
        console.error("Error fetching vendor details:", error);
        this.$swal.fire({
          icon: "error",
          title: error.response?.data?.message || "Vendor not found",
          toast: true,
          position: "top-end",
          timer: 5000,
          showConfirmButton: false,
        });
      } finally {
        this.isLoading = false;
      }
    },
    populateFormFields() {
      if (this.vendorData) {
        this.businessName = this.vendorData.name || "";
        this.email = this.vendorData.email || "";
        this.vendorImage = this.vendorData.profile_image || "";
        this.countryCode = this.vendorData.country_code || "";
        this.phone = this.vendorData.phone_number || "";
        this.country = this.vendorData.address[0]?.country || "";
        const state = this.vendorData.address[0]?.state || {};
        this.selectedStateId = state.id || ""; // Set the selected state by ID
        this.state = state.name || ""; // Set the full state name
        this.address = this.vendorData.address[0]?.address || "";
        this.amount = this.amount || "0.00";
      }
    },
    startEditing() {
      this.isEditing = true;
    },
    async updateVendor() {
      const vendorId = this.$route.query.id;
      if (!vendorId) return;

      const payload = {
        name: this.businessName,
        email: this.email,
        phone_number: this.phone,
        country_code: "+61",
        address: {
          country: this.country,
          address: this.address,
          state_name: this.selectedStateId, // Use the selected state ID
        },
      };

      this.isLoading = true;
      try {
        const response = await axios.post(`/vendor/${vendorId}`, payload, {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("access")).access_token
            }`,
          },
        });
        if (response.data.status) {
          console.log("Update Success:", response.data);
          this.$swal.fire({
            icon: "success",
            title: response.data.message,
            timer: 3000,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
          });
          this.isEditing = false;
          this.fetchVendorData(); // Refresh data after update
        }
      } catch (error) {
        console.error("Error updating vendor:", error);
        this.$swal.fire({
          icon: "error",
          title: error.response?.data?.message || "Failed to update vendor",
          toast: true,
          position: "top-end",
          timer: 5000,
          showConfirmButton: false,
        });
      } finally {
        this.isLoading = false;
      }
    },
    async deleteVendor() {
      const vendorId = this.$route.query.id;
      if (!vendorId) return;

      try {
        const confirmation = await this.$swal.fire({
          title: "Are you sure?",
          text: "This action cannot be undone!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Yes, delete it!",
        });

        if (confirmation.isConfirmed) {
          this.isLoading = true;
          const response = await axios.delete(`/vendor/${vendorId}`, {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem("access")).access_token
              }`,
            },
          });
          if (response.data.status) {
            console.log("Delete Success:", response.data);
            this.$swal.fire({
              icon: "success",
              title: response?.data?.message,
              timer: 2000,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
            });
            // Wait for alert to be shown before redirecting
            setTimeout(() => {
              this.$router.push({ name: "AdminVendors" });
            }, 1000); // Delay redirect by 1 second to ensure alert is visible
          } else {
            throw new Error(
              response?.data?.message || "Failed to delete vendor"
            );
          }
        }
      } catch (error) {
        console.error("Error deleting vendor:", error);
        this.$swal.fire({
          icon: "error",
          title: error.response?.data?.message || "Failed to delete vendor",
          toast: true,
          position: "top-end",
          timer: 5000,
          showConfirmButton: false,
        });
      } finally {
        this.isLoading = false;
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date
        .toLocaleDateString("en-AU", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        })
        .replace(",", "");
    },
    async deleteNote(noteId) {
      try {
        const confirmation = await this.$swal.fire({
          title: "Note Actions",
          text: "This action cannot be undone!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Delete",
        });

        if (confirmation.isConfirmed) {
          this.isLoading = true;
          const response = await axios.delete(`/notes/${noteId}`, {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem("access")).access_token
              }`,
            },
          });

          // Check if response has a message (success case)
          if (response.data.message) {
            // Refresh the vendor data to get updated notes
            await this.fetchVendorData();

            this.$swal.fire({
              icon: "success",
              title: "Note deleted successfully",
              toast: true,
              position: "top-end",
              timer: 3000,
              showConfirmButton: false,
            });
          } else {
            throw new Error("No response message received");
          }
        }
      } catch (error) {
        console.error("Error deleting note:", error);
        this.$swal.fire({
          icon: "error",
          title: error.response?.data?.message || "Failed to delete note",
          toast: true,
          position: "top-end",
          timer: 5000,
          showConfirmButton: false,
        });
      } finally {
        this.isLoading = false;
      }
    },
    async addNote() {
      // Validate content
      if (!this.noteContent.trim()) {
        this.$swal.fire({
          icon: "error",
          title: "Note content cannot be empty",
          toast: true,
          position: "top-end",
          timer: 3000,
          showConfirmButton: false,
        });
        return;
      }

      try {
        this.isLoading = true;
        const payload = {
          user_id: this.vendorData.id,
          content: this.noteContent,
          subject: this.subject || "No Subject", // Use default if subject is empty
        };

        const response = await axios.post("/notes/add", payload, {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("access")).access_token
            }`,
          },
        });

        if (response.data) {
          // Clear the form
          this.noteContent = "";
          this.subject = "";

          // Refresh notes list
          await this.fetchVendorData();

          this.$swal.fire({
            icon: "success",
            title: "Note added successfully",
            toast: true,
            position: "top-end",
            timer: 3000,
            showConfirmButton: false,
          });
        }
      } catch (error) {
        console.error("Error adding note:", error);
        this.$swal.fire({
          icon: "error",
          title: error.response?.data?.message || "Failed to add note",
          toast: true,
          position: "top-end",
          timer: 5000,
          showConfirmButton: false,
        });
      } finally {
        this.isLoading = false;
      }
    },
    startEditingNote(note) {
      this.editingNote = { ...note };
    },
    cancelEditingNote() {
      this.editingNote = null;
    },
    async saveEditedNote() {
      if (!this.editingNote) return;

      // Validate content
      if (!this.editingNote.content.trim()) {
        this.$swal.fire({
          icon: "error",
          title: "Note content cannot be empty",
          toast: true,
          position: "top-end",
          timer: 3000,
          showConfirmButton: false,
        });
        return;
      }

      try {
        this.isLoading = true;
        const response = await axios.put(
          `/notes/${this.editingNote.id}`,
          {
            content: this.editingNote.content,
            subject: this.editingNote.subject || "No Subject", // Add default subject if empty
          },
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem("access")).access_token
              }`,
            },
          }
        );

        if (response.data) {
          await this.fetchVendorData(); // Refresh notes
          this.editingNote = null; // Close edit form

          this.$swal.fire({
            icon: "success",
            title: "Note updated successfully",
            toast: true,
            position: "top-end",
            timer: 3000,
            showConfirmButton: false,
          });
        }
      } catch (error) {
        console.error("Error updating note:", error);
        this.$swal.fire({
          icon: "error",
          title: error.response?.data?.message || "Failed to update note",
          toast: true,
          position: "top-end",
          timer: 3000,
          showConfirmButton: false,
        });
      } finally {
        this.isLoading = false;
      }
    },
    async applyCredit() {
      if (!this.creditAmount || !this.creditReason) {
        this.$swal.fire({
          icon: "error",
          title: "Please fill in all fields",
          toast: true,
          position: "top-end",
          timer: 3000,
          showConfirmButton: false,
        });
        return;
      }

      try {
        this.isLoading = true;
        const currentAmount = parseFloat(this.amount) || 0;
        const creditAmount = parseFloat(this.creditAmount) || 0;
        // Calculate new amount based on credit type
        const newAmount =
          this.creditType === "apply_credit"
            ? currentAmount + creditAmount
            : currentAmount - creditAmount;

        const payload = {
          user_id: parseInt(this.vendorData.id),
          amount: newAmount,
          reason: this.creditReason,
          status: this.creditType, // Use the selected status
        };

        console.log("Sending payload:", payload);

        const response = await axios.post("/wallet/credit", payload, {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("access")).access_token
            }`,
            "Content-Type": "application/json",
          },
        });

        if (response.data.success) {
          // Reset form and close modal
          this.creditAmount = "";
          this.creditReason = "";
          this.creditType = "apply_credit"; // Reset to default
          this.showCreditModal = false;

          // Refresh vendor data to show updated balance
          await this.fetchVendorData();
          this.$swal.fire({
            icon: "success",
            title: `Credit ${
              this.creditType === "apply_credit" ? "applied" : "deducted"
            } successfully`,
            toast: true,
            position: "top-end",
            timer: 3000,
            showConfirmButton: false,
          });
        } else {
          throw new Error(response.data.message || "Failed to process credit");
        }
      } catch (error) {
        console.error("Error processing credit:", error);
        this.$swal.fire({
          icon: "error",
          title: error.response?.data?.message || "Failed to process credit",
          toast: true,
          position: "top-end",
          timer: 3000,
          showConfirmButton: false,
        });
      } finally {
        this.isLoading = false;
      }
    },
    goToInvoices() {
      this.$router.push({
        name: "AdminVendorInvoices",
        query: { id: this.$route.query.id }, // Pass the current vendor ID
      });
    },
  },
};
</script>

<style scoped>
/* Add Material Icons font if not already added in your main HTML */
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
.bg-teal-900 {
  background-color: #06262d;
}
.arrow-left-icon {
  color: #00a388;
  background-color: #002f28;
  display: inline-flex;
  border-radius: 4px;
}

.header-padding {
  padding-left: 15%;
  padding-right: 15%;
}

@media (min-width: 768px) {
  .header-padding {
    padding-left: 15%;
    padding-right: 15%;
  }
}

@media (max-width: 767px) {
  .header-padding {
    padding-left: 0%;
    padding-right: 0%;
  }
}
</style>
