<template>
  <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />

  <div class="min-h-screen w-full bg-teal-900 px-6">
    <!-- Header -->
    <AdminHeader />

    <!-- Main Content -->
    <div class="max-w-4xl mx-auto pb-8">
      <!-- Title -->
      <h1 class="text-white text-2xl text-left mb-10">
        Customers
        <span style="color: rgb(0, 163, 136);" class="-ml-1 text-4xl">.</span>
      </h1>

      <div
        class="flex gap-8 flex-col md:flex-row"
        v-if="!isLoading && customer"
      >
        <!-- Left Column -->
        <div class="w-full md:w-1/2 space-y-6">
          <div>
            <label class="text-white text-md text-left mb-2 block"
              >Customer Name</label
            >
            <input
              type="text"
              v-model="customer.name"
              placeholder="Customer Name"
              class="w-full p-4 rounded-md bg-white focus:outline-none"
            />
          </div>

          <div>
            <label class="text-white text-md text-left mb-2 block">Phone</label>
            <input
              type="tel"
              v-model="formattedPhone"
              placeholder="+61 0000 0000"
              class="w-full p-4 rounded-md bg-white focus:outline-none"
            />
          </div>

          <div>
            <label class="text-white text-md mb-2 text-left block">Email</label>
            <input
              type="email"
              v-model="customer.email"
              placeholder="Email"
              class="w-full p-4 rounded-md bg-white focus:outline-none"
            />
          </div>

          <div class="pt-4">
            <div class="flex items-center gap-2 mb-16">
              <span class="text-white text-md">Scans</span>
              <span class="text-white text-2xl">#252</span>
            </div>
            <div class="space-y-3 md:w-40">
              <button
                @click="showResetPasswordModal"
                class="w-full py-4 text-white rounded"
                style="background-color: #00A388"
              >
                Reset Password
              </button>
              <button
                @click="updateCustomer"
                class="w-full py-4 text-white rounded"
                style="background-color: #FFA726"
              >
                Save
              </button>

              <button
                @click="deleteCustomer"
                class="w-full py-4 text-white rounded"
                style="background-color: #E91E63"
              >
                Delete
              </button>
            </div>
          </div>
        </div>

        <!-- Right Column -->
        <div class="w-full md:w-1/2 space-y-4">
          <!-- Notes Section -->
          <div>
            <!-- Add Subject Input -->
            <div class="mb-4">
              <label class="text-white text-md text-left block mb-2"
                >Subject</label
              >
              <input
                type="text"
                v-model="noteSubject"
                placeholder="Enter subject"
                class="w-full p-4 rounded-md bg-white focus:outline-none"
              />
            </div>

            <div class="flex justify-between items-center mb-2">
              <label class="text-white text-md text-left">Notes</label>
              <div class="flex justify-end">
                <div class="relative dropdown-container" ref="tagDropdown">
                  <button
                    @click.stop="isTagDropdownOpen = !isTagDropdownOpen"
                    class="px-10 py-2 text-white rounded-2xl border text-sm flex items-center gap-2"
                    style="background-color: transparent;border-color: #00A388;"
                  >
                    Add Tag
                    <span
                      class="material-icons text-sm transition-transform duration-200"
                      :style="{
                        transform: isTagDropdownOpen
                          ? 'rotate(180deg)'
                          : 'rotate(0deg)',
                      }"
                    >
                      expand_more
                    </span>
                  </button>
                  <!-- Dropdown Menu with Checkboxes -->
                  <div
                    v-if="isTagDropdownOpen"
                    class="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-50 dropdown-menu"
                    style="min-width: 160px; max-height: 188px; overflow: hidden; overflow-y: auto;"
                  >
                    <div class="py-1">
                      <div
                        v-for="tag in availableTags"
                        :key="tag.id"
                        class="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                        @click="toggleTag(tag)"
                      >
                        <input
                          type="checkbox"
                          :checked="isTagSelected(tag.id)"
                          class="mr-2"
                          @click.stop="toggleTag(tag)"
                        />
                        {{ tag.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Selected Tags Display (chips) -->
            <div class="flex flex-wrap gap-2 mt-3 mb-2">
              <div
                v-for="tag in selectedTags"
                :key="tag.id"
                class="px-3 py-1 text-white rounded-full flex items-center gap-1"
                style="background-color: #00A388;"
              >
                <span class="text-sm">{{ tag.name }}</span>
                <button
                  @click="removeTag(tag.id)"
                  class="flex items-center justify-center w-4 h-4"
                >
                  <span
                    class="material-icons"
                    style="font-size: 14px; line-height: 1;"
                  >
                    close
                  </span>
                </button>
              </div>
            </div>
            <textarea
              v-model="noteContent"
              class="w-full h-24 p-4 rounded-md bg-white focus:outline-none resize-none mb-4"
              placeholder="Type Here"
            ></textarea>

            <!-- Submit Button -->
            <div class="flex justify-end">
              <button
                @click="submitNote"
                class="w-24 py-2 text-white rounded"
                style="background-color: #00A388"
              >
                Submit
              </button>
            </div>
          </div>

          <!-- Messages Section -->
          <div class="space-y-4 mt-6">
            <div
              v-for="note in notes"
              :key="note.id"
              :class="{
                'opacity-20 transition-opacity duration-200':
                  editingNote && editingNote.id !== note.id,
              }"
            >
              <div class="flex justify-between text-gray-400 text-xs mb-1">
                <span>{{ new Date(note.created_at).toLocaleString() }}</span>
                <div class="flex gap-2">
                  <button
                    @click="startEditingNote(note)"
                    class="w-6 h-6 rounded-full flex items-center justify-center"
                    style="background-color: rgba(255, 255, 255, 0.1)"
                  >
                    <span
                      class="material-icons text-white"
                      style="font-size: 12px;"
                      >edit_square</span
                    >
                  </button>
                  <button
                    @click="deleteNote(note.id)"
                    class="w-6 h-6 rounded-full flex items-center justify-center"
                    style="background-color: rgba(255, 255, 255, 0.1)"
                    :disabled="editingNote"
                    :class="{ 'opacity-50 cursor-not-allowed': editingNote }"
                  >
                    <span
                      class="material-icons text-white"
                      style="font-size: 12px;"
                      >close</span
                    >
                  </button>
                </div>
              </div>

              <!-- Edit Mode -->
              <div v-if="editingNote && editingNote.id === note.id">
                <input
                  type="text"
                  v-model="editingNote.subject"
                  class="w-full p-2 mb-2 rounded-md bg-white focus:outline-none"
                  placeholder="Enter subject"
                />

                <!-- Tags Selection for Edit Mode -->
                <div class="mb-2">
                  <div class="flex justify-end">
                    <div
                      class="relative dropdown-container"
                      ref="editTagDropdown"
                    >
                      <button
                        @click.stop="
                          isEditTagDropdownOpen = !isEditTagDropdownOpen
                        "
                        class="px-4 py-2 text-white rounded-2xl border text-sm flex items-center gap-2 mb-2"
                        style="background-color: transparent;border-color: #00A388;"
                      >
                        Edit Tags
                        <span
                          class="material-icons text-sm transition-transform duration-200"
                          :style="{
                            transform: isEditTagDropdownOpen
                              ? 'rotate(180deg)'
                              : 'rotate(0deg)',
                          }"
                        >
                          expand_more
                        </span>
                      </button>

                      <div
                        v-if="isEditTagDropdownOpen"
                        style="max-height: 188px; overflow: hidden; overflow-y: auto;"
                        class="absolute right-0 -mt-1 w-48 bg-white rounded-md shadow-lg z-50 dropdown-menu"
                      >
                        <div class="py-1">
                          <div
                            v-for="tag in availableTags"
                            :key="tag.id"
                            class="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                            @click="toggleEditTag(tag)"
                          >
                            <input
                              type="checkbox"
                              :checked="isTagInEditMode(tag.id)"
                              class="mr-2"
                              @click.stop="toggleEditTag(tag)"
                            />
                            {{ tag.name }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Selected Tags in Edit Mode -->
                  <div class="flex flex-wrap gap-2">
                    <div
                      v-for="tag in editingNote.tags"
                      :key="tag.id"
                      class="px-3 py-1 text-white rounded-full flex items-center gap-1"
                      style="background-color: #00A388;"
                    >
                      <span class="text-sm">{{ tag.name }}</span>
                      <button
                        @click="removeEditTag(tag.id)"
                        class="flex items-center justify-center w-4 h-4"
                      >
                        <span class="material-icons" style="font-size: 14px;"
                          >close</span
                        >
                      </button>
                    </div>
                  </div>
                </div>

                <textarea
                  v-model="editingNote.content"
                  class="w-full h-24 p-2 rounded-md bg-white focus:outline-none resize-none mb-2"
                ></textarea>

                <div class="flex justify-end gap-2">
                  <button
                    @click="cancelEditingNote"
                    class="px-4 py-1 text-white rounded"
                    style="background-color: #666"
                  >
                    Cancel
                  </button>
                  <button
                    @click="saveEditedNote"
                    class="px-4 py-1 text-white rounded"
                    style="background-color: #00A388"
                  >
                    Save
                  </button>
                </div>
              </div>

              <!-- Display Mode -->
              <div v-else>
                <!-- Tags Display -->
                <div class="flex gap-2 mt-6 mb-2" style="flex-wrap: wrap;">
                  <button
                    v-for="tag in note.tags"
                    :key="tag.id"
                    class="px-10 py-2 text-white rounded-2xl border text-sm tagspaddingsetting"
                    style="background-color: transparent; border-color: #00A388;"
                  >
                    {{ tag.name }}
                  </button>
                </div>
                <div class="text-white text-left text-md mb-1">
                  {{ note.subject ? note.subject : "No Subject" }}
                </div>
                <div class="text-gray-400 text-left text-sm">
                  {{ note.content }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Back Button -->
      <button
        @click="goBack"
        class="mt-14  text-white gap-5 px-6 py-4 rounded-md flex items-center space-x-2"
        style="background-color: #204952"
      >
        <span class="material-icons text-xl arrow-left-icon">arrow_left</span>
        <span>Back</span>
      </button>
    </div>
  </div>

  <div v-if="showPasswordModal" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div class="bg-white rounded-lg p-6 w-full max-w-md">
      <h2 class="text-xl font-semibold mb-4 text-gray-800">Reset Password</h2>
      
      <p class="mb-6 text-gray-600">Would you like to send this user a password reset email?</p>

      <!-- Action buttons -->
      <div class="flex justify-end gap-3">
        <button
          @click="closeResetPasswordModal"
          class="px-4 py-2 text-gray-600 rounded hover:bg-gray-100"
        >
          No
        </button>
        <button
          @click="sendResetPasswordEmail"
          class="px-4 py-2 text-white rounded"
          style="background-color: #00A388"
        >
          Yes
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import AdminHeader from "@/components/AdminHeader.vue";
import axios from "axios";
import Loading from "vue-loading-overlay";

export default {
  name: "Customer",
  components: {
    AdminHeader,
    Loading,
  },
  data() {
    return {
      logo_transition: false,
      customer: {},
      notes: [],
      noteSubject: "",
      isLoading: false,
      fullPage: true,
      loader: "bars",
      searchTimeout: null,
      formattedPhone: "",
      error: null,
      isTagDropdownOpen: false,
      availableTags: [],
      selectedTags: [],
      noteContent: "",
      editingNote: null,
      isEditTagDropdownOpen: false,
      showPasswordModal: false,
    };
  },
  async created() {
    this.isLoading = true;
    try {
      const customerId = this.$route.query.id;
      

     const response = await axios.get(`/customer/${customerId}`, {
    headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('access')).access_token}`
    }
});

      if (response.data.status) {
        this.customer = response.data.customer;
        this.notes = response.data.notes;
        this.availableTags = response.data.tags;
        this.formattedPhone = `${this.customer.phone_number}`;
      } else {
        this.customer = null;
        throw new Error(
          response.data.message || "Failed to load customer data"
        );
      }
    } catch (error) {
      this.customer = null;
      this.error = "Failed to load customer data";

      this.$swal.fire({
        icon: "error",

        title: error.response?.data?.message || "Failed to load customer data",
        timer: 3000,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
      });

      setTimeout(() => {
        this.$router.push({ name: "AdminCustomers" });
      }, 2000);

      console.error(error);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    goBack() {
      this.$router.push({ name: "AdminCustomers" });
    },
    async updateCustomer() {
      this.isLoading = true;
      try {
        const customerId = this.customer.id;
        const payload = {
          name: this.customer.name,
          email: this.customer.email,
          phone_number: this.customer.phone_number,
          country_code: "+61",
        };

        const response = await axios.post(`/customer/${customerId}`, payload, {
    headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('access')).access_token}`
    }
});

        if (response.data.status) {
          this.$swal.fire({
            icon: "success",
            title: response.data.message,
            toast: true,
            position: "top-end",
            timer: 3000,
            showConfirmButton: false,
          });
        } else {
          this.$swal.fire({
            icon: "error",
            title: response.data.message,
            toast: true,
            position: "top-end",
            timer: 3000,
            showConfirmButton: false,
          });
        }
      } catch (err) {
        console.error("Error updating customer:", err);
        this.$swal.fire({
          icon: "error",
          title: err.response?.data?.message || "Failed to update vendor",
          toast: true,
          position: "top-end",
          timer: 3000,
          showConfirmButton: false,
        });
      } finally {
        this.isLoading = false;
      }
    },
    async deleteCustomer() {
      const customerId = this.$route.query.id;
      if (!customerId) return;

      try {
        const confirmation = await this.$swal.fire({
          title: "Are you sure?",
          text: "This action cannot be undone!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Yes, delete it!",
        });

        if (confirmation.isConfirmed) {
          this.isLoading = true;
          const response = await axios.delete(`/customer/${customerId}`, {
    headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('access')).access_token}`
    }
});

          if (response && response.data && response.data.status) {
            this.isLoading = false;
            await this.$swal.fire({
              icon: "success",
              title: response.data.message || "Customer deleted successfully",
              timer: 2000,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
            });

            setTimeout(() => {
              this.$router.push({ name: "AdminCustomers" });
            }, 1000);
          } else {
            throw new Error(
              response?.data?.message || "Failed to delete customer"
            );
          }
        }
      } catch (error) {
        console.error("Error deleting customer:", error);
        this.$swal.fire({
          icon: "error",
          title: error.response?.data?.message || "Failed to delete customer",
          toast: true,
          position: "top-end",
          timer: 3000,
          showConfirmButton: false,
        });
      } finally {
        this.isLoading = false;
      }
    },
    async deleteNote(noteId) {
      try {
        const { isConfirmed } = await this.$swal.fire({
          title: "Note Actions",
          html: `
            <div class="text-center">
              <p class="mb-4">This action cannot be undone!</p>
            </div>
          `,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#808080",
          confirmButtonText: "Delete",
          cancelButtonText: "Cancel",
        });

        if (isConfirmed) {
          this.isLoading = true;

          const response = await axios.delete(`/notes/${noteId}`, {
    headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('access')).access_token}`
    }
});

          if (response.status >= 200 && response.status < 300) {
            this.notes = this.notes.filter((note) => note.id !== noteId);

            const customerId = this.$route.query.id;
            const refreshResponse = await axios.get(`/customer/${customerId}`, {
    headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('access')).access_token}`
    }
});
            if (refreshResponse.data.status) {
              this.notes = refreshResponse.data.notes;
              this.$swal.fire({
                icon: "success",
                title: "Note deleted successfully",
                toast: true,
                position: "top-end",
                timer: 3000,
                showConfirmButton: false,
              });
            }
          } else {
            throw new Error("Failed to delete note");
          }
        }
      } catch (error) {
        console.error("Error deleting note:", error);
        this.$swal.fire({
          icon: "error",
          title: "Failed to delete note",
          toast: true,
          position: "top-end",
          timer: 3000,
          showConfirmButton: false,
        });
      } finally {
        this.isLoading = false;
      }
    },
    toggleTag(tag) {
      const index = this.selectedTags.findIndex((t) => t.id === tag.id);
      if (index === -1) {
        this.selectedTags.push(tag);
      } else {
        this.selectedTags.splice(index, 1);
      }
    },
    isTagSelected(tagId) {
      return this.selectedTags.some((tag) => tag.id === tagId);
    },
    removeTag(tagId) {
      this.selectedTags = this.selectedTags.filter((tag) => tag.id !== tagId);
    },
    async submitNote() {
      if (!this.noteContent.trim()) {
        this.$swal.fire({
          icon: "error",
          title: "Note content is required",
          toast: true,
          position: "top-end",
          timer: 3000,
          showConfirmButton: false,
        });
        return;
      }

      try {
        this.isLoading = true;
        const payload = {
          user_id: this.customer.id,
          content: this.noteContent.trim(),
          tags: this.selectedTags.map((tag) => tag.name),
          subject: this.noteSubject.trim(),
        };

        const response = await axios.post("/notes/add", payload, {
    headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('access')).access_token}`
    }
});

        if (response.data.note) {
          // Add the new note from the API response directly to the beginning of notes array
          this.notes = [response.data.note, ...this.notes];

          // Clear form
          this.noteContent = "";
          this.noteSubject = "";
          this.selectedTags = [];
          this.isTagDropdownOpen = false;

          this.$swal.fire({
            icon: "success",
            title: response.data.message || "Note added successfully",
            toast: true,
            position: "top-end",
            timer: 3000,
            showConfirmButton: false,
          });
        } else {
          throw new Error(response.data.message || "Failed to add note");
        }
      } catch (error) {
        console.error("Error adding note:", error);
        this.$swal.fire({
          icon: "error",
          title: error.response?.data?.message || "Failed to add note",
          toast: true,
          position: "top-end",
          timer: 3000,
          showConfirmButton: false,
        });
      } finally {
        this.isLoading = false;
      }
    },
    handleClickOutside(event) {
      // Check if click target is a button or inside a dropdown
      const isButton = event.target.closest("button");
      const isDropdown = event.target.closest(".dropdown-menu");

      // If click is not on a button and not inside a dropdown, close all dropdowns
      if (!isButton && !isDropdown) {
        this.isTagDropdownOpen = false;
        this.isEditTagDropdownOpen = false;
      }
    },
    startEditingNote(note) {
      this.editingNote = {
        ...note,
        tags: [...note.tags],
      };
    },
    cancelEditingNote() {
      this.editingNote = null;
      this.isEditTagDropdownOpen = false;
    },
    toggleEditTag(tag) {
      if (!this.editingNote) return;

      const index = this.editingNote.tags.findIndex((t) => t.id === tag.id);
      if (index === -1) {
        this.editingNote.tags.push(tag);
      } else {
        this.editingNote.tags.splice(index, 1);
      }
    },
    isTagInEditMode(tagId) {
      return this.editingNote?.tags.some((tag) => tag.id === tagId) || false;
    },
    removeEditTag(tagId) {
      if (!this.editingNote) return;
      this.editingNote.tags = this.editingNote.tags.filter(
        (tag) => tag.id !== tagId
      );
    },
    async saveEditedNote() {
      if (!this.editingNote) return;

      // Validate content
      if (!this.editingNote.content.trim()) {
        this.$swal.fire({
          icon: "error",
          title: "Note content cannot be empty",
          toast: true,
          position: "top-end",
          timer: 3000,
          showConfirmButton: false,
        });
        return;
      }

      try {
        this.isLoading = true;
        const payload = {
          content: this.editingNote.content.trim(),
          subject: this.editingNote.subject || "No Subject", // Add default subject if empty
          tags: this.editingNote.tags.map((tag) => tag.name),
        };

        const response = await axios.put(
          `/notes/${this.editingNote.id}`,
          payload
        , {
    headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('access')).access_token}`
    }
});

        if (response.data) {
          // Refresh the customer data to get updated notes
          const customerId = this.$route.query.id;
          const refreshResponse = await axios.get(
            `/customer/${customerId}`
          , {
    headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('access')).access_token}`
    }
});
          if (refreshResponse.data.status) {
            this.notes = refreshResponse.data.notes;
          }

          this.editingNote = null;
          this.isEditTagDropdownOpen = false;

          this.$swal.fire({
            icon: "success",
            title: "Note updated successfully",
            toast: true,
            position: "top-end",
            timer: 3000,
            showConfirmButton: false,
          });
        }
      } catch (error) {
        console.error("Error updating note:", error);
        this.$swal.fire({
          icon: "error",
          title: error.response?.data?.message || "Failed to update note",
          toast: true,
          position: "top-end",
          timer: 3000,
          showConfirmButton: false,
        });
      } finally {
        this.isLoading = false;
      }
    },
    showResetPasswordModal() {
      this.showPasswordModal = true;
    },
    closeResetPasswordModal() {
      this.showPasswordModal = false;
    },
    async sendResetPasswordEmail() {
      try {
        this.isLoading = true;
        const response = await axios.post('/customer/reset-password/by/admin', {
          email: this.customer.email
        }, {
          headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem('access')).access_token}`
          }
        });

        if (response.data.status) {
          this.$swal.fire({
            icon: 'success',
            title: 'A Password Reset Email Has Been Sent',
            toast: true,
            position: 'top-end',
            timer: 3000,
            showConfirmButton: false
          });
          this.closeResetPasswordModal();
        }
      } catch (error) {
        console.error('Error sending reset password email:', error);
        this.$swal.fire({
          icon: 'error',
          title: error.response?.data?.message || 'Failed to send reset password email',
          toast: true,
          position: 'top-end',
          timer: 3000,
          showConfirmButton: false
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
  watch: {
    formattedPhone(newValue) {
      if (!this.customer) return;

      const numbers = newValue.replace(/\D/g, "");

      if (numbers.length >= 2) {
        this.customer.country_code = numbers.substring(0, 2);
        this.customer.phone_number = numbers.substring(2);
      }
    },
  },
  mounted() {
    this.logo_transition = true;
    document.addEventListener("click", this.handleClickOutside, true);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside, true);
  },
};
</script>

<style scoped>
/* Add Material Icons font if not already added in your main HTML */
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
.bg-teal-900 {
  background-color: #06262d;
}
.arrow-left-icon {
  color: #00a388;
  background-color: #002f28;
  display: inline-flex;
  border-radius: 4px;
}

.header-padding {
  padding-left: 15%;
  padding-right: 15%;
}

@media (min-width: 768px) {
  .header-padding {
    padding-left: 15%;
    padding-right: 15%;
  }
  
}

@media (max-width: 767px) {
  .header-padding {
    padding-left: 0%;
    padding-right: 0%;
  }
  .tagspaddingsetting {
    padding-left: 0.6rem !important;
    padding-right: 0.6rem !important;
  }
}

:deep(.swal2-textarea) {
  width: 100%;
  min-height: 150px;
  padding: 12px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: vertical;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.z-50 {
  z-index: 50;
}

.cursor-pointer {
  cursor: pointer;
}

/* Checkbox styling */
input[type="checkbox"] {
  accent-color: #00a388;
  width: 16px;
  height: 16px;
}

/* Prevent text selection in dropdown */
.dropdown-item {
  user-select: none;
}

.transition-transform {
  transition: transform 0.2s ease-in-out;
}

.dropdown-container {
  position: relative;
}
</style>
