<template>
  <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
  <div class="min-h-screen w-full bg-teal-900 px-6">
    <!-- Header -->
    <AdminHeader />
    <!-- Search Section -->
    <div class="max-w-4xl mx-auto pb-8">
      <h1 class="text-white text-left text-2xl mb-4">
        Search Vendors
        <span
          class="-ml-1 text-4xl leading-none"
          style="color: rgb(0, 163, 136);"
          >.</span
        >
      </h1>

      <!-- Search Filters -->
      <div class="flex gap-4 mb-8">
        <input
          type="text"
          placeholder="Name"
          class="flex-1 p-3 rounded-md bg-white focus:outline-none"
          v-model="searchName"
          @input="debouncedSearch"
        />
        <div class="relative flex-1">
          <select
            class="w-full p-3 rounded-md bg-white appearance-none focus:outline-none"
            v-model="selectedState"
            @change="debouncedSearch"
          >
            <option value="">All States</option>
            <option
              v-for="state in states"
              :key="state.code"
              :value="state.code"
            >
              {{ state.name }}
            </option>
          </select>
          <div
            class="absolute right-3 top-1/2 transform flex -translate-y-1/2 pointer-events-none"
          >
            <span
              class="material-icons arrow-down-icon justify-center items-center"
            >
              arrow_drop_down
            </span>
          </div>
        </div>
      </div>

      <!-- Vendors Grid -->
      <div class="grid grid-cols-2 gap-x-8 gap-y-6">
        <div
          v-if="vendors.length > 0"
          v-for="vendor in vendors"
          :key="vendor.id"
          class="flex items-center md:gap-5 space-x-4 cursor-pointer hover:opacity-80 transition-opacity"
          @click="viewVendorDetails(vendor)"
        >
          <!-- Vendor Avatar -->
          <div class="w-16 h-16 bg-white rounded-full flex-shrink-0">
            <img
              v-if="vendor.profile_image"
              :src="vendor.profile_image"
              class="w-full h-full rounded-full object-cover"
              alt="vendor profile"
            />
          </div>

          <!-- Vendor Info -->
          <div>
            <h3 class="text-white text-lg font-medium text-left">
              {{ vendor.name }}
            </h3>
            <div class="flex items-center space-x-2">
              <span class="text-gray-300 text-sm">Status:</span>
              <span style="color:#00A388" class="text-sm">Active</span>
            </div>
          </div>
        </div>
      </div>

      <!-- Back Button -->
      <button
        @click="$router.back()"
        class="mt-14 bg-teal-800 gap-5 text-white px-6 py-4 rounded-md flex items-center space-x-2"
        style="background-color: #204952"
      >
        <span class="material-icons text-xl arrow-left-icon">arrow_left</span>
        <span>Back</span>
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import AdminHeader from "../../components/AdminHeader.vue";
import Loading from "vue-loading-overlay";

export default {
  name: "Vendors",
  components: {
    AdminHeader,
    Loading
  },
  data() {
    return {
      logo_transition: false,
      searchName: "",
      selectedState: "",
      states: [
        { code: "NSW", name: "New South Wales" },
        { code: "VIC", name: "Victoria" },
        { code: "QLD", name: "Queensland" },
        { code: "SA", name: "South Australia" },
        { code: "WA", name: "Western Australia" },
        { code: "TAS", name: "Tasmania" },
        { code: "ACT", name: "Australian Capital Territory" },
        { code: "NT", name: "Northern Territory" },
        { code: "Unknown", name: "Unknown" }
      ],
      vendors: [],
      isLoading: false,
      fullPage: true,
      loader: "bars",
      searchTimeout: null
    };
  },
  mounted() {
    this.logo_transition = true;
    this.fetchVendors();
  },
  methods: {
    async fetchVendors() {
      this.isLoading = true;
      try {
        const response = await axios.get("/all/vendors", {
          params: {
            name: this.searchName,
            state: this.selectedState
          }, 
    headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('access')).access_token}`
    }
        });

        if (response.data.status) {
          this.vendors = response.data.vendors;
        } else {
          // Clear vendors array when no vendors are found
          this.vendors = [];
          // Show alert for no vendors
          this.$swal.fire({
            icon: "info",
            title: response.data.message,
            timer: 3000,
            toast: true,
            position: "top-end",
            showConfirmButton: false
          });
        }
      } catch (error) {
        console.error("Error fetching vendors:", error);
        this.vendors = []; // Clear vendors on error
        this.$swal.fire({
          icon: "error",
          title: error.response?.data?.message || "Something went wrong",

          timer: 3000,
          toast: true,
          position: "top-end",
          showConfirmButton: false
        });
      } finally {
        this.isLoading = false;
      }
    },
    debouncedSearch(value) {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(() => {
        this.fetchVendors();
      }, 2000); // 2 seconds delay
    },
    viewVendorDetails(vendor) {
      // Store vendor data in localStorage or Vuex store
      localStorage.setItem(
        "selectedVendor",
        JSON.stringify({
          id: vendor.id,
          name: vendor.name,
          email: vendor.email,
          phone: vendor.phone_number,
          country_code: vendor.country_code,
          profile_image: vendor.profile_image,
          address:
            vendor.address && vendor.address[0]
              ? {
                  address: vendor.address[0].address,
                  suburb: vendor.address[0].suburb,
                  postcode: vendor.address[0].postcode,
                  state: vendor.address[0].state?.name,
                  country: vendor.address[0].country
                }
              : null
        })
      );

      // Navigate to vendor details page
      this.$router.push({
        name: "AdminVendor",
        query: { id: vendor.id } // Pass vendor ID in URL for reference
      });
    }
  },
  watch: {
    searchName: {
      handler(newVal) {
        this.debouncedSearch(newVal);
      }
    },
    selectedState() {
      // State change should trigger immediate search
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      this.fetchVendors();
    }
  }
};
</script>

<style scoped>
/* Add Material Icons font if not already added in your main HTML */
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
.bg-teal-900 {
  background-color: #06262d;
}
.arrow-left-icon {
  color: #00a388; /* Custom text color */
  background-color: #002f28; /* Custom background color */
  display: inline-flex; /* Ensures background color wraps the icon */

  border-radius: 4px; /* Optional rounded corners */
}
.arrow-down-icon {
  color: #214952; /* Custom text color */
  background-color: #00000029; /* Custom background color */
  display: inline-flex; /* Ensures background color wraps the icon */

  border-radius: 4px; /* Optional rounded corners */
}
select.w-full.p-3.rounded-md.bg-white.appearance-none.focus\:outline-none {
  background-image: none !important;
}
.header-padding {
  padding-left: 15%;
  padding-right: 15%;
}

@media (min-width: 768px) {
  .header-padding {
    padding-left: 15%;
    padding-right: 15%;
  }
}
@media (max-width: 767px) {
  .header-padding {
    padding-left: 0%;
    padding-right: 0%;
  }
}

/* Add hover effect styles */
.cursor-pointer {
  cursor: pointer;
}

.hover\:opacity-80:hover {
  opacity: 0.8;
}

.transition-opacity {
  transition: opacity 0.2s ease-in-out;
}
</style>
